import * as React from 'react';
import PropTypes from 'prop-types';
import { PageProps } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from '../components/ui/layout';
import NumuLegal from '../components/legal';
import DefaultLanguage from '../hoc/default-language';
import SEO from '../components/ui/seo';

import 'bootstrap/dist/css/bootstrap.css';
import '../style/style.scss';

const Terms = ({ location, pageContext }) => (
  <>
    <SEO page="terms" language={pageContext.language} />
    <DefaultLanguage location={location}>
      <Layout location={location} language={pageContext.language} page="terms">
        <div className="pricingContainer">
          <NumuLegal language={pageContext.language} type="tos_client" />
        </div>
      </Layout>
    </DefaultLanguage>
  </>
);

Terms.propTypes = {
  location: PropTypes.instanceOf(PageProps).isRequired,
  pageContext: PropTypes.instanceOf(PageProps).isRequired
};

export default injectIntl(Terms);
